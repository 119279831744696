<script>
  import attachBanner from "@beyonk/gdpr-cookie-consent-banner";

  import Header from "./components/Header.svelte";
  import Footer from "./components/Footer.svelte";
  import Banner from "./components/Banner.svelte";
  import About from "./components/About.svelte";
  import Services from "./components/Services.svelte";
  import Portfolio from "./components/Portfolio.svelte";

  attachBanner(document.body);
</script>

<style>
  @import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap");
  /* main {
    text-align: center;
    padding: 1em;
    max-width: 240px;
    margin: 0 auto;
  }

  h1 {
    color: #ff3e00;
    text-transform: uppercase;
    font-size: 4em;
    font-weight: 100;
  }

  @media (min-width: 640px) {
    main {
      max-width: none;
    }
  } */
</style>

<Header />
<!-- <Banner /> -->
<About />
<Services />
<Portfolio />
<Footer />
