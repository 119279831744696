<script>
  import PortfolioList from "./PortfolioList.svelte";
  let heading = "Featured Work";
</script>

<style>
  #portfolio {
    /* width: 80%; */
    margin: 0 auto;
    padding: 60px;
  }

  .portfolio-heading {
    padding: 0 15px;
    font-size: 2.6em;
    font-weight: bold;
    position: relative;
  }

  .portfolio-heading::after {
    content: "";
    position: absolute;
    width: 7%;
    height: 1px;
    top: 107%;
    left: 1%;
    border-bottom: 5px solid #ee4700;
  }

  /* Mobile Styles */
  @media (min-width: 320px) and (max-width: 420px) {
    #portfolio {
      padding: 0;
      padding-bottom: 30px;
    }
    .portfolio-heading::after {
      left: 47%;
    }
    h3 {
      text-align: center;
    }
  }
</style>

<div id="portfolio">
  <h3 class="portfolio-heading">{heading}</h3>
  <PortfolioList />
</div>
