<script>
  export let backgroundImage;
  export let title;
  export let description;
  export let link;
</script>

<style>
  .work {
    width: 410px;
    height: 300px;
    margin: 10px 15px;
    background-position-x: 40%;
    position: relative;
  }
  h3,
  h4 {
    text-align: center;
    font-weight: 420;
    line-height: 0;
  }
  .description {
    display: flex;
    flex-direction: column;
    justify-content: center;
    background: #ee4700;
    opacity: 0;
    color: white;
    position: absolute;
    width: 410px;
    height: 300px;
    top: 0px;
    transition-duration: 700ms;
  }

  .work .description:hover {
    opacity: 0.7;
  }

  /* Tablet Styles */
  @media (min-width: 421px) and (max-width: 768px) {
    .work {
      margin: 10px auto;
    }
  }

  /* Mobile Styles */
  @media (min-width: 320px) and (max-width: 420px) {
    .work {
      width: 100%;
      margin: 20px 0;
    }
    .description {
      margin: 0;
    }
  }
</style>

<div class="work">
  <a href={link} target="_blank">
    <img src={backgroundImage} alt="workImage" />
    <div class="description">
      <h3>{title}</h3>
      <h4>{description}</h4>
    </div>
  </a>
</div>
