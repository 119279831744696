<style>
  .menu ul {
    text-align: center;
  }
  .menu li {
    font-size: 1.3em;
    display: inline;
    list-style: none;
    margin: 15px;
    color: #ffffff;
  }
  .menu li a {
    color: #ffffff;
    text-decoration: none;
  }

  /* Mobile Styles */
  @media (min-width: 320px) and (max-width: 425px) {
    .menu ul {
      display: flex;
      justify-content: center;
      padding: 5px;
    }
    .menu li {
      font-size: 1.2em;
      margin: 8px;
    }
  }
</style>

<div class="menu">
  <ul>
    <a href="#about">
      <li>About</li>
    </a>
    <a href="#services">
      <li>Services</li>
    </a>
    <a href="#portfolio">
      <li>Work</li>
    </a>
    <a href="#footer">
      <li>Contact</li>
    </a>

  </ul>
</div>
