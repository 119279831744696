<script>
  import Work from "./Work.svelte";
  let workList = [
    {
      title: "Polbeth MOT Station",
      img: "/img/pms.png",
      services: "Web Design",
      link: "https://www.polbethmotstation.co.uk/"
    },
    {
      title: "WKES",
      img: "/img/wkes.png",
      services: "Web Design",
      link: "https://www.wk-es.co.uk"
    }
  ];
</script>

<style>
  .portfolio-row {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin: auto;
  }

  /* Tablet Styles */
  @media (min-width: 421px) and (max-width: 768px) {
    .portfolio-row {
      flex-wrap: wrap;
    }
  }

  /* Mobile Styles */
  @media (min-width: 320px) and (max-width: 420px) {
    .portfolio-row,
    .portfolio-row a {
      width: 100%;
      display: inline-block;
    }
  }
</style>

<div class="portfolio-row">
  {#each workList as work}
    <Work
      backgroundImage={work.img}
      title={work.title}
      description={work.services}
      link={work.link} />
  {/each}
</div>
