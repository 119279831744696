<style>
  #banner {
    width: 100%;
    background-image: url("/img/banner-bg.png");
    background-repeat: no-repeat;
    padding: 15px 0 20px 140px;
  }
  h1 {
    font-weight: 600;
    color: #ffffff;
    margin: 0;
    /* line-height: 1.5em; */
    font-size: 2.2em;
    width: 60%;
  }

  /* Tablet Styles */
  @media (min-width: 421px) and (max-width: 768px) {
    #banner {
      width: fit-content;
      padding: 15px 20px;
    }
    h1 {
      text-align: center;
      width: 100%;
    }
  }

  /* Mobile Styles */
  @media (min-width: 320px) and (max-width: 420px) {
    #banner {
      padding: 0;
      background-color: #ee4700;
      background-image: none;
    }
    h1 {
      font-size: 1.6em;
      text-align: center;
      padding: 5px;
      width: 100%;
    }
  }
</style>

<div id="banner">
  <h1>Building digital avenues to communicate with your audience.</h1>
  <!-- <h2>Designing user friendly websites and web applications.</h2> -->
</div>
